.column_cancel_btn{
    /* background-color:;
*/
 margin-left: 10px;
}
.column_search{
    width:250px;
    margin-bottom: 8px;
}
.column_search_container{
    width:250px;
    padding: 12px;
}